exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-crossfit-js": () => import("./../../../src/pages/crossfit.js" /* webpackChunkName: "component---src-pages-crossfit-js" */),
  "component---src-pages-horarios-js": () => import("./../../../src/pages/horarios.js" /* webpackChunkName: "component---src-pages-horarios-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nutricion-js": () => import("./../../../src/pages/nutricion.js" /* webpackChunkName: "component---src-pages-nutricion-js" */),
  "component---src-pages-personalizado-js": () => import("./../../../src/pages/personalizado.js" /* webpackChunkName: "component---src-pages-personalizado-js" */),
  "component---src-pages-planes-js": () => import("./../../../src/pages/planes.js" /* webpackChunkName: "component---src-pages-planes-js" */)
}

